@charset 'UTF-8';
@import "/Users/alivevn/Website/vhost/brains/src/scss/utility/_media-queries.scss";
@import "/Users/alivevn/Website/vhost/brains/src/scss/utility/_mixin.scss";


@keyframes moveArr {
  0% {
    @include transform_c(translateY(0));
  }
  25% {
    @include transform_c(translateY(-3px));
  }
  50% {
    @include transform_c(translateY(0));
  }
  75% {
    @include transform_c(translateY(3px));
  }
  100% {
    @include transform_c(translateY(0));
  }
}
@keyframes moveArrSp {
  0% {
    @include transform_c(translateY(0));
  }
  25% {
    @include transform_c(translateY(-2px));
  }
  50% {
    @include transform_c(translateY(0));
  }
  75% {
    @include transform_c(translateY(2px));
  }
  100% {
    @include transform_c(translateY(0));
  }
}

.slide_img {

  &__content {
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    width: 100%;
    height: 100%;
    z-index: 3;

    .thumb {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 1;
    }
    &.slide_left {
      transform: translate3d(-100%,0,0);

      .thumb {
        transform: translate3d(100%,0,0);
      }
    }
    &.slide_right {
      transform: translate3d(100%,0,0);

      .thumb {
        transform: translate3d(-100%,0,0);
      }
    }
  }
  &.animated {

    .slide_img__content {
      transition: transform .8s cubic-bezier(.165,.84,.44,1);
      transform: translate3d(0,0,0);
      transition-delay: .3s;

      .thumb {
        opacity: 1;
        transition: transform .8s cubic-bezier(.165,.84,.44,1),opacity 0s .8s;
        transform: translate3d(0,0,0);
        transition-delay: .3s;
      }
    }
  }
}

.top {

  &__cmnhd {
    text-align: center;
    @include jost;

    &--en {
      display: block;
      font-size: 52px;
      letter-spacing: 7.3px;
      margin-bottom: 34px;
      padding-left: 8px;
    }
    &--jp {
      font-weight: 500;
      font-size: 16px;
      letter-spacing: 1.5px;
      color: $darkgray;
      @include off-font-smooth;
    }
  }
  &__property,
  &__events,
  &__points,
  &__works,
  &__voice {

    .cmn_btn {
      margin: 0 auto;
    }
  }
  &__mainimg {
    height: 100vh;
    position: relative;
    display: flex;
    flex-flow: wrap column;
    justify-content: flex-start;

    &--img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      .thumb {
        height: 100%;
      }
    }
    &--content {
      width: 100%;
      position: relative;
      z-index: 2;
      top: 146px;

      .title {
        font-family: $jost;
        font-size: 50px;
        font-weight: 500;
        letter-spacing: 8.85px;
        margin-bottom: 22px;
        @include off-font-smooth;
      }
      p {
        font-weight: bold;
        font-size: 16px;
        line-height: 2.15;
        letter-spacing: 3.2px;
        padding-left: 6px;
      }
    }
    &--scroll {
      position: absolute;
      left: 0;
      right: 0;
      margin: 0 auto;
      bottom: 31px;
      width: 100%;

      a {
        display: block;
        width: 40px;
        @include jost;
        font-size: 8px;
        color: $colortext;
        text-transform: uppercase;
        letter-spacing: 0.6px;
        text-align: center;
        margin: auto;

        i {
          display: block;
          width: 40px;
          height: 40px;
          border: 1px solid $colortext;
          @include border-radius(50%);
          position: relative;
          margin-top: 9px;

          &::before {
            content: "";
            display: block;
            width: 8px;
            height: 17px;
            background: url(../../img/top/ico_scroll.svg) no-repeat top left;
            background-size: 100% 100%;
            position: absolute;
            left: 0;
            right: 0;
            top: 10px;
            margin: 0 auto;
            backface-visibility: hidden;
            -webkit-transform-origin: top center;
            transform-origin: top center;
            -webkit-animation: moveArr 2s linear infinite;
            animation: moveArr 2s linear infinite;
          }
        }
      }
    }
  }
  &__property {
    background: #2c2825;
    color: #fff;
    padding: 86px 0 112px;
    margin-bottom: 105px;
    position: relative;

    .top__cmnhd--jp {
      color: #fff;
    }
    &--bg {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
    &--inner {
      width: 87.5%;
      margin: 0 auto;
      position: relative;
      z-index: 2;
    }
    &--list {
      display: flex;
      flex-wrap: wrap;
      margin: 69px -10px 40px;

      li {
        width: 50%;
        padding: 0 10px;
        margin-bottom: 20px;

        .content {
          background: #fff;
          position: relative;
          display: flex;
          flex-flow: wrap row;

          .zimg_frame {
            width: 51%;
            @include aspect-ratio(354,263);
          }
          .zimg_bg {
            position: absolute;
            top: 0;
            left: 0;
          }
          .txt {
            width: 49%;
            font-weight: bold;
            padding: 21px 22px 14px 26px;

            .title {
              font-size: 19px;
              font-weight: bold;
              line-height: 1.65;
              letter-spacing: 0.8px;
              margin-bottom: 13px;
            }
            span {
              display: block;

              &.location,&.construct {
                position: relative;
                padding-left: 32px;
                margin-bottom: 4px;
                letter-spacing: 1.2px;

                &::before {
                  content: "";
                  display: block;
                  position: absolute;
                  background-repeat: no-repeat;
                  background-position: top left;
                  background-size: 100% 100%;
                }
              }
              &.location {

                &::before {
                  width: 18px;
                  height: 18px;
                  left: 0;
                  top: 2px;
                  background-image: url(../../img/common/icon/ico_location.svg);
                }
              }
              &.construct {

                &::before {
                  width: 19px;
                  height: 16px;
                  left: 0;
                  top: 3px;
                  background-image: url(../../img/common/icon/ico_construct.svg);
                }
              }
              &.price {
                border-top: 1px solid #D0D0D0;
                text-align: right;
                font-size: 19px;
                margin-top: 20px;
                padding-top: 8px;
                letter-spacing: 1px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;

                em {
                  font-size: 26px;
                  margin-right: -1px;
                  letter-spacing: 2.2px;
                }
              }
            }
          }
        }
      }
    }
  }
  &__events {
    margin-bottom: 130px;

    .top__cmnhd {

      &--en {
        margin-bottom: 23px;
      }
      &--jp {
        color: #8D9BA8;
      }
    }
    &--list {
      display: flex;
      flex-wrap: wrap;
      margin: 59px -20px 48px;

      li {
        width: 33.33333%;
        padding: 0 20px;
        margin-bottom: 20px;

        .out {
          cursor: default;
          pointer-events: none;

          .content {

            .expired {
              display: flex;
            }
          }
        }
        .content {
          background: #fff;

          .zimg_frame {
            @include aspect-ratio(340,250);
          }
          .zimg_bg {
            position: absolute;
            top: 0;
            left: 0;
          }
          .expired {
            background: rgba($color: #2a2c2e, $alpha: 0.8);
            color: #fff;
            @include absolute(100%, 100%, 0, "", "", 0);
            display: none;
            @include align-items();
            @include justify-content();
            @include font(22, 39, 0.1, bold);
            text-align: center;
            z-index: 3;
          }
          .txt {
            font-weight: bold;
            margin-top: 18px;

            .title {
              font-weight: bold;
              margin-top: 8px;
            }
            span {
              display: block;

              &.cat {
                display: inline-block;
                vertical-align: top;
                margin: 0 3px 6px 0;
                font-size: 13px;
                color: #fff;
                background: $lblue;
                padding: 6px 9px 5px 9px;
                line-height: 13px;
                min-width: 70px;
                text-align: center;
              }
              &.date {
                font-size: 13px;
                color: $darkgray;
                margin-top: 10px;
                letter-spacing: 0.65px;
              }
            }
          }
        }
      }
    }
  }
  &__parallax {

    .parallax,.simpleParallax {
      width: 100%;
      height: 100%;

      .thumb {
        height: 100%;
      }
    }
    &.parallax01 {
      height: 367px;
    }
    &.parallax02 {
      height: 360px;
    }
  }
  &__points {
    background: $gray;
    padding: 116px 0 112px;

    &--title {
      text-align: center;
      font-size: 42px;
      font-family: $jost;
      font-weight: 500;
      @include off-font-smooth;
      line-height: 1.68;
      letter-spacing: 3.7px;
      margin-bottom: 63px;
    }
    &--list {
      display: flex;
      flex-wrap: wrap;
      text-align: center;
      justify-content: space-between;
      margin: 0 -32px 42px;

      li {
        width: 33.33333%;
        padding: 0 57px;
        position: relative;

        &::before {
          content: "";
          width: 38px;
          height: 38px;
          background: url(../../img/common/icon/ico_close_square.svg) no-repeat top left;
          background-size: 100% 100%;
          position: absolute;
          top: 50%;
          margin-top: -34px;
          right: -19px;
        }
        &:last-child::before {
          display: none;
        }
        .meta {
          position: relative;
          padding-top: 93px;

          &::before {
            content: "";
            display: block;
            background-repeat: no-repeat;
            background-position: top left;
            background-size: 100% 100%;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            margin: 0 auto;
          }
        }
        .title,.subtitle {
          @include jost;
          letter-spacing: 2.4px;
        }
        .title {
          font-size: 28px;
          color: #6F0C23;
          margin-bottom: 13px;
        }
        .subtitle {
          font-size: 12px;
          color: $darkgray;
          text-transform: uppercase;
          margin-bottom: 31px;
        }
        .spec {
          font-weight: bold;
          background: #fff;
          font-size: 16px;
          margin-bottom: 12px;
          line-height: 23px;
          padding-top: 1px;
          letter-spacing: 1.3px;

          &:last-child {
            margin-bottom: 0;
          }
        }
        .note {
          text-align: right;
          font-weight: bold;
          font-size: 11px;
          letter-spacing: 0.8px;
          margin-top: -1px;
        }
        &:first-child .meta::before {
          width: 60px;
          height: 60px;
          background-image: url(../../img/common/icon/ico_point01.svg);
        }
        &:nth-child(2) .meta::before {
          width: 60px;
          height: 64px;
          background-image: url(../../img/common/icon/ico_point02.svg);
        }
        &:nth-child(3) .meta::before {
          width: 60px;
          height: 62px;
          background-image: url(../../img/common/icon/ico_point03.svg);
        }
      }
    }
    &--desc {
      font-weight: bold;
      max-width: 880px;
      margin: 0 auto 66px;
      line-height: 1.9;
      letter-spacing: 1.2px;
    }
    &--btn {
      margin-top: 120px;
    }
  }
  &__money {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    background: $gray;
    padding-bottom: 197px;

    &--img,&--txt {
      width: 50%;
    }
    &--img {
      height: 566px;
      position: relative;

      .lb {
        width: 100%;
        @include jost;
        position: absolute;
        bottom: -22px;
        left: -33px;
        text-align: center;
        font-size: 52px;
        letter-spacing: 7.3px;
        z-index: 4;
      }
    }
    &--txt {
      position: relative;
      padding: 84px 18px 29px 96px;

      &::before {
        content: "";
        display: block;
        width: 84.4vw;
        height: calc(100% + 89px);
        background: #fff;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 0;
      }
      .box {
        position: relative;
        z-index: 2;
        max-width: 480px;
      }
      .title {
        font-weight: 500;
        font-size: 36px;
        font-family: $jost;
        @include off-font-smooth;
        margin-bottom: 30px;
        line-height: 1.75;
        letter-spacing: 3.2px;
      }
      p {
        font-weight: bold;
        padding-right: 20px;
        margin-bottom: 52px;
      }
    }
  }
  &__works {
    position: relative;
    padding: 136px 0 107px;
    background: #2c2825;
    color: #fff;

    &--bg {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
    &--inner {
      position: relative;
      z-index: 2;
      display: flex;
      flex-wrap: wrap;
      width: 87.5%;
      margin: 0 auto;
    }
    &--left {
      width: 300px;
      padding-top: 5px;
    }
    &--right {
      width: calc(100% - 300px);
    }
    &--list {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -10px 72px;

      li {
        width: 25%;
        padding: 0 10px;

        .content {
          color: #fff;
          font-weight: bold;

          .zimg_frame {
            @include aspect-ratio(260,190);
          }
          .zimg_bg {
            position: absolute;
            top: 0;
            left: 0;
          }
          .title {
            font-weight: bold;
            margin-top: 23px;
          }
          p {
            font-size: 13px;
            color: #8D9BA8;
            margin-top: 14px;
            letter-spacing: 0.65px;
          }
        }
      }
    }
    .top__cmnhd {
      text-align: left;

      &--en {
        padding-left: 0;
        margin-bottom: 44px;
      }
      &--jp {
        color: #fff;
      }
    }
  }
  &__voice {
    margin: 102px 0 110px;

    .top__cmnhd--en {
      margin-bottom: 19px;
    }
    &--list {
      display: flex;
      flex-wrap: wrap;
      margin: 69px -10px 72px;

      li {
        width: 25%;
        padding: 0 10px;

        .content {
          font-weight: bold;

          .zimg_frame {
            @include aspect-ratio(260,190);
          }
          .zimg_bg {
            position: absolute;
            top: 0;
            left: 0;
          }
          .title {
            font-weight: bold;
            margin-top: 23px;
          }
          p {
            font-size: 13px;
            color: #88776C;
            margin-top: 15px;
            letter-spacing: 0.65px;
          }
        }
      }
    }
  }
  &__news {
    background: $gray;
    padding: 132px 0 117px;

    &--inner {
      display: flex;
      flex-wrap: wrap;
    }
    &--left {
      width: 41.3%;

      .top__cmnhd {
        text-align: left;
        margin-bottom: 44px;

        &--en {
          padding-left: 0;
          margin-bottom: 28px;
        }
      }
      .cmn_btn {
        max-width: 270px;
      }
    }
    &--list {
      width: 58.7%;
      padding-top: 4px;

      li {
        margin-bottom: 36px;

        a {
          display: block;
        }
        .content {
          display: flex;
          flex-wrap: wrap;
          font-weight: bold;

          span {
            position: relative;

            &.date {
              width: 95px;
              top: 5px;
              letter-spacing: 0.7px;
              font-size: 15px;
              font-weight: 500;
              @include tajawal;
            }
            &.cat {
              font-size: 11px;
              color: #fff;
              background: $lblue;
              width: 78px;
              height: 18px;
              line-height: 18px;
              text-align: center;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              top: 2px;
              letter-spacing: 0.5px;
            }
          }
          .title {
            width: calc(100% - 173px);
            font-weight: bold;
            font-size: 14px;
            padding-left: 16px;
          }
        }
      }
    }
  }
  &__store {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    background: $gray;
    padding-bottom: 224px;

    &--img,&--txt {
      width: 50%;
    }
    &--img {
      order: 2;
      height: 566px;
      position: relative;

      .lb {
        width: 100%;
        @include jost;
        position: absolute;
        bottom: -30px;
        left: 6px;
        text-align: center;
        font-size: 52px;
        letter-spacing: 7.3px;
        z-index: 4;
      }
    }
    &--txt {
      order: 1;
      position: relative;
      background: #fff;
      padding: 151px 33px 49px 18px;

      &::before {
        content: "";
        display: block;
        width: 84.4vw;
        height: calc(100% + 93px);
        background: #fff;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 0;
      }
      .box {
        width: 100%;
        max-width: 568px;
        float: right;
        position: relative;
        z-index: 2;
      }
      .title,.subtitle {
        font-family: $jost;
        @include off-font-smooth;
        font-weight: 500;
      }
      .subtitle {
        font-size: 22px;
        margin-bottom: 1px;
        letter-spacing: 2px;
      }
      .title {
        font-size: 36px;
        margin-bottom: 15px;
        line-height: 1.75;
        letter-spacing: 3.2px;
      }
      .ct {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-bottom: 63px;

        &__left,&__right {
          width: 50%;
        }
        &__left {
          font-weight: bold;
          position: relative;
          padding-top: 6px;

          &::before {
            content: "";
            width: 1px;
            height: 88px;
            position: absolute;
            top: 0;
            right: 0;
            background: $darkgray;
          }
          p {
            margin-bottom: 6px;
          }
          a {
            display: inline-block;
            vertical-align: top;
            padding-left: 26px;
            position: relative;

            &::before {
              content: "";
              display: block;
              width: 15px;
              height: 7px;
              background: url(../../img/common/icon/ico_ar_right_black_bold.svg) no-repeat top left;
              background-size: 100% 100%;
              position: absolute;
              top: 50%;
              margin-top: -3px;
              left: 0;
            }
            span {
              text-decoration: underline;
              display: block;
            }
          }
        }
        &__right {
          padding-left: 33px;

          a {
            display: block;
          }
          .content {

            p {
              padding-left: 33px;
              position: relative;
              @include jost;

              &::before {
                content: "";
                width: 24px;
                height: 32px;
                background: url(../../img/common/icon/ico_tel_black.svg) no-repeat top left;
                background-size: 100% 100%;
                position: absolute;
                left: 0;
                top: 7px;
              }
              span,em {
                display: block;
              }
              span {
                font-size: 24px;
                letter-spacing: 2.3px;
                margin-bottom: 7px;
              }
              em {
                font-size: 10px;
                padding-left: 3px;
                letter-spacing: 0.8px;
              }
            }
          }
        }
      }
    }
  }
  &__seo {
    padding-bottom: 196px;
    background: $gray;

    &--list {

      li {
        margin-bottom: 74px;

        &:last-child {
          margin-bottom: 0;
        }
        .content {
          display: flex;
          flex-wrap: wrap;
          align-items: center;

          .img {
            width: 55.6%;
            height: 540px;

            .thumb {
              height: 100%;
            }
          }
          .txt {
            width: 44.4%;

            .box {
              max-width: 568px;
              width: 100%;
              margin-left: -108px;
              position: relative;
              top: 7px;
            }
            .title {
              font-weight: 500;
              font-family: $jost;
              font-size: 28px;
              margin-bottom: 29px;
            }
            .desc {
              margin-bottom: 45px;
            }
          }
        }
        &:nth-child(2) {

          .content {

            .img {
              order: 2;
            }
            .txt {
              order: 1;

              .box {
                margin-left: 0;
                margin-right: -108px;
                float: right;
              }
            }
          }
        }
      }
    }
  }
  @include screen(768px,1023px) {

    &__property--inner,
    &__works--inner {
      width: 100%;
      padding: 0 18px;
    }
    &__property--list li {
      width: 100%;
    }
    &__points {

      &--title {
        font-size: 38px;
      }
      &--list {
        margin-left: -20px;
        margin-right: -20px;

        li {
          padding: 0 20px;

          .title {
            font-size: 26px;
          }
          .spec {
            font-size: 11px;
          }
        }
      }
    }
    &__events--list {
      margin-left: -10px;
      margin-right: -10px;

      li {
        padding: 0 10px;
      }
    }
    &__money {

      &--img .lb {
        font-size: 46px;
        left: 0;
        bottom: -21px;
      }
      &--txt {
        padding: 30px 18px 30px 30px;

        .title {
          font-size: 26px;
          letter-spacing: 1px;
        }
        p {
          font-size: 14px;
        }
        .cmn_btn {
          max-width: 270px;
        }
      }
    }
    &__works {

      &--left {
        width: 220px;
      }
      &--right {
        width: calc(100% - 220px);
      }
      &--list {
        margin-bottom: 52px;

        li {
          width: 50%;
          margin-bottom: 20px;
        }
      }
    }
    &__news {

      &--left {
        width: 40%;

        .cmn_btn {
          max-width: 220px;
        }
      }
      &--right {
        width: 60%;
      }
    }
    &__store--txt {

      .title {
        font-size: 32px;
      }
      .ct {

        &__left,&__right {
          width: 100%;
        }
        &__left {
          margin-bottom: 30px;

          &::before {
            display: none;
          }
        }
        &__right {
          padding-left: 0;
        }
      }
      .cmn_btn {
        max-width: 270px;
      }
    }
    &__seo {

      &--list {

        li {

          .content {

            .img,.txt {
              width: 50%;
            }
            .txt {

              .box {
                margin: 0 !important;
                padding: 0 25px;
              }
              .title {
                font-size: 24px;
              }
              .desc {
                font-size: 13px;
              }
              .cmn_btn {
                max-width: 300px;
              }
            }
          }
        }
      }
    }
  }
  @include screen(1024px,1199px) {

    &__property--list li .content .txt {

      .title {
        font-size: 15px;
      }
      span {
        font-size: 13px;
      }
    }
    &__points--list {
      margin-left: -30px;
      margin-right: -30px;

      li {
        padding: 0 30px;
      }
    }
    &__money--txt {
      padding-left: 50px;

      .title {
        letter-spacing: 2px;
        font-size: 34px;
      }
    }
    &__works {

      &--left {
        width: 240px;
      }
      &--right {
        width: calc(100% - 240px);
      }
    }
    &__news {

      &--left {
        width: 35%;
      }
      &--right {
        width: 65%;
      }
    }
    &__store--txt .ct {

      &__left {
        font-size: 14px;
      }
      &__right {
        padding-left: 20px;
      }
    }
  }
  @include PC {

    &__events,
    &__money,
    &__voice,
    &__news,
    &__store {

      .cmn_btn:hover {
        border-color: $colortext;
      }
    }
  }
  @include max-screen(767px) {

    &__cmnhd {

      &--en {
        font-size: 36px;
        letter-spacing: 5px;
        margin-bottom: 16px;
        padding-left: 2px;
      }
      &--jp {
        font-size: 12px;
        letter-spacing: 1.1px;
      }
    }
    &__property--inner,
    &__works--inner {
      width: 100%;
      padding: 0 18px;
    }
    &__mainimg {

      &--content {
        padding-left: 22px;
        top: 48px;
        text-align: center;

        .title {
          font-size: 32px;
          line-height: 1.4;
          letter-spacing: 5.6px;
          margin-bottom: 10px;
        }
        p {
          font-size: 14px;
          line-height: 2;
          letter-spacing: 1.4px;
          padding: 0;
        }
      }
      &--scroll {
        bottom: 26px;

        a {
          letter-spacing: 0.7px;
          color: #fff;

          i {
            width: 24px;
            height: 24px;
            margin: 7px auto 0;
            border-color: #fff;

            &::before {
              width: 5px;
              height: 11px;
              top: 5px;
              animation-name: moveArrSp;
              background-image: url(../../img/top/ico_scroll_white.svg);
            }
          }
        }
      }
    }
    &__property {
      padding: 47px 0 66px;
      margin-bottom: 54px;

      &--list {
        margin: 33px -9px 23px;

        li {
          padding: 0 9px;

          .content {

            .zimg_frame,.txt {
              width: 100%;
            }
            .zimg_frame {
              @include aspect-ratio(161,120);
            }
            .txt {
              padding: 13px 13px 8px;

              .title {
                font-size: 14px;
                letter-spacing: 0.4px;
                margin-bottom: 9px;
              }
              span {

                &.location,&.construct {
                  font-size: 12px;
                  padding-left: 25px;
                  letter-spacing: 0.2px;
                }
                &.location {

                  &::before {
                    width: 15px;
                    height: 15px;
                    left: 3px;
                    top: 1px;
                  }
                }
                &.construct {

                  &::before {
                    width: 14px;
                    height: 12px;
                    left: 3px;
                    top: 3px;
                  }
                }
                &.price {
                  margin-top: 19px;
                  font-size: 14px;
                  padding: 4px 2px 0 0;

                  em {
                    font-size: 19px;
                    margin-right: 0;
                    letter-spacing: 1.5px;
                  }
                }
              }
            }
          }
        }
      }
    }
    &__events {
      margin-bottom: 72px;

      .top__cmnhd {

        &--en {
          margin-bottom: 13px;
        }
      }
      &--list {
        margin-top: 29px;
        margin-bottom: 8px;

        li {
          width: 100%;
          max-width: 100%;
          margin-bottom: 31px;

          .content {

            .txt span.cat {
              padding-top: 5px;
              padding-bottom: 6px;
            }
            .expired {
              @include font(14, 25, 0.1, bold);
            }
          }
        }
      }
    }
    &__parallax {

      &.parallax01,&.parallax02 {
        height: 140px;
      }
    }
    &__points {
      padding: 51px 0 32px;

      &--title {
        font-size: 26px;
        line-height: 1.55;
        letter-spacing: 2.2px;
        margin-bottom: 55px;
      }
      &--list {
        padding: 0 32px;
        margin: 0 0 31px;

        li {
          padding: 0;
          width: 100%;
          margin-bottom: 92px;

          &::before {
            left: 0;
            right: 0;
            margin: 0 auto;
            width: 30px;
            height: 30px;
            top: auto;
            bottom: -56px;
          }
          .meta {
            display: inline-block;
            vertical-align: top;
            padding: 0 0 0 72px;
            text-align: left;

            &::before {
              margin: 0;
            }
            .title {
              font-size: 21px;
              letter-spacing: 2px;
              margin-bottom: 9px;
            }
            .subtitle {
              font-size: 11px;
              padding-left: 4px;
              letter-spacing: 2.3px;
              margin-bottom: 23px;
            }
          }
          .spec {
            letter-spacing: 1.2px;
            padding-top: 0;
            padding-bottom: 2px;
            line-height: 22px;
          }
          &:first-child .meta::before {
            width: 49px;
            height: 49px;
            top: -4px;
          }
          &:nth-child(2) .meta::before {
            width: 50px;
            height: 53px;
            top: -10px;
          }
          &:last-child {
            margin-bottom: 0;

            .meta::before {
              width: 50px;
              height: 52px;
              top: -9px;
            }
          }
        }
      }
      &--desc {
        font-size: 14px;
        margin: 0 -1px 41px;
        letter-spacing: 1.1px;
        font-weight: 400;
      }
      &--btn {
        margin-top: 78px;
      }
    }
    &__money {
      display: block;
      padding-bottom: 60px;

      &--img {
        width: calc(100% - 75px);
        height: auto;
        @include aspect-ratio(300,212);

        &::after {
          content: "";
          width: calc(100% + 57px);
          height: calc(100% - 60px);
          background: #fff;
          bottom: 0;
          right: -75px;
          position: absolute;
          z-index: 0;
        }
        .thumb {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          z-index: 1;
        }
        .lb {
          width: calc(100% + 75px);
          padding-right: 14px;
          text-align: right;
          font-size: 24px;
          left: 0;
          bottom: 22px;
          letter-spacing: 3.3px;
        }
      }
      &--txt {
        width: 100%;
        background: none;
        padding: 0 0 0 18px;

        &::before {
          display: none;
        }
        .box {
          background: #fff;
          max-width: 100%;
          padding: 23px 27px 52px 29px;
        }
        .title {
          font-size: 22px;
          letter-spacing: 2px;
          margin-bottom: 18px;
        }
        p {
          padding-right: 0;
          margin-bottom: 26px;
          font-weight: 400;
        }
      }
    }
    &__works {
      padding: 53px 0 78px;

      &--left,&--right {
        width: 100%;
      }
      &--left {

        .top__cmnhd {
          text-align: center;

          &--en {
            margin-bottom: 13px;
          }
        }
      }
      &--list {
        margin: 34px -9px 19px;

        li {
          width: 50%;
          margin-bottom: 21px;
          padding: 0 9px;

          .content {

            .title {
              margin-top: 16px;
            }
            p {
              margin-top: 7px;
              line-height: 1.7;
            }
          }
        }
      }
      .cmn_btn {
        left: -6px;
      }
    }
    &__voice {
      margin: 63px 0 76px;

      .top__cmnhd--en {
        letter-spacing: 3.7px;
        margin-bottom: 12px;
      }
      &--list {
        margin: 39px -9px 12px;

        li {
          width: 50%;
          margin-bottom: 25px;
          padding: 0 9px;

          .content {

            .title {
              margin-top: 16px;
            }
            p {
              margin-top: 7px;
              line-height: 1.7;
            }
          }
        }
      }
      .cmn_btn {
        left: -6px;
      }
    }
    &__news {
      padding: 53px 0 81px;

      &--left,&--list {
        width: 100%;
      }
      &--left {

        .top__cmnhd {
          text-align: center;
          margin-bottom: 37px;

          &--en {
            margin-bottom: 13px;
          }
        }
      }
      &--list {
        padding: 0;
        margin-bottom: 10px;

        li {
          margin-bottom: 27px;

          .content {

            span {

              &.date {
                font-size: 14px;
              }
            }
            .title {
              width: 100%;
              padding: 0;
              margin-top: 12px;
              line-height: 1.65;
            }
          }
        }
      }
      .cmn_btn {
        margin: 0 auto;
        left: -6px;
      }
    }
    &__store {
      display: block;
      padding-bottom: 60px;

      &--img {
        width: calc(100% - 75px);
        height: auto;
        @include aspect-ratio(300,212);
        margin: 0 0 0 auto;

        &::after {
          content: "";
          width: calc(100% + 57px);
          height: calc(100% - 60px);
          background: #fff;
          bottom: 0;
          left: -75px;
          position: absolute;
          z-index: 0;
        }
        .thumb {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          z-index: 1;
        }
        .lb {
          width: calc(100% + 75px);
          padding-left: 29px;
          text-align: left;
          font-size: 24px;
          right: 0;
          left: auto;
          bottom: 16px;
          letter-spacing: 3.3px;
        }
      }
      &--txt {
        width: 100%;
        background: none;
        padding: 0 18px 0 0;

        &::before {
          display: none;
        }
        .box {
          background: #fff;
          max-width: 100%;
          padding: 38px 21px 53px 21px;
          float: none;
        }
        .title,.subtitle {
          padding-left: 8px;
        }
        .subtitle {
          font-size: 16px;
          letter-spacing: 1.4px;
        }
        .title {
          font-size: 22px;
          letter-spacing: 2px;
          margin-bottom: 13px;
        }
        .ct {
          margin-bottom: 32px;

          &__left,&__right {
            width: 100%;
          }
          &__left {
            padding: 0 0 0 8px;
            margin-bottom: 29px;

            &::before {
              display: none;
            }
            p {
              margin-bottom: 9px;
              font-weight: 400;
            }
            a {
              margin-left: 3px;
            }
          }
          &__right {
            padding: 0;

            .content {
              padding: 15px 23px 11px;
              background: #fff;
              border: 1px solid #aaa;
              box-shadow: 3px 3px 6px 0px rgba(0,0,0,.05);
              text-align: center;

              p {
                display: inline-block;
                vertical-align: top;
                padding-left: 31px;
                margin-bottom: 11px;

                &::before {
                  width: 24px;
                  height: 32px;
                  top: 6px;
                }
                span {
                  font-size: 24px;
                  letter-spacing: 3.9px;
                  margin-bottom: 6px;
                }
                em {
                  font-size: 11px;
                  padding-right: 2px;
                  letter-spacing: 0.9px;
                }
              }
              small {
                background: $gray;
                font-size: 11px;
                display: block;
                font-weight: bold;
                line-height: 1;
                height: 22px;
                padding-top: 5px;
                letter-spacing: 0.9px;
              }
            }
          }
        }
        .cmn_btn {
          margin: 0 auto;
        }
      }
    }
    &__seo {
      padding-bottom: 100px;

      &--list {

        li {
          margin-bottom: 60px;

          .content {
            display: block;

            .img {
              width: calc(100% - 18px);
              height: auto;
              @include aspect-ratio(357,217);

              .thumb {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
              }
            }
            .txt {
              width: 100%;
              padding: 0 18px;

              .box {
                float: none !important;
                max-width: 100%;
                position: static;
                margin: 0 !important;
              }
              .title {
                font-size: 22px;
                margin: 26px 0 16px;
                letter-spacing: 2px;
                line-height: 1.5;
              }
              .desc {
                font-size: 14px;
                line-height: 1.7;
                margin-bottom: 52px;
                font-weight: 400;
              }
              .cmn_btn {
                margin: 0 auto;
                left: -15px;
              }
            }
          }
          &:nth-child(2) {

            .img {
              margin: 0 0 0 auto;
            }
          }
        }
      }
    }
  }
  @include max-screen(374px) {

    &__property--list li {
      width: 100%;
    }
    &__points--list {
      padding: 0;

      li .spec {
        font-size: 13px;
      }
    }
    &__store--txt {

      .ct__right .content {
        padding-left: 10px;
        padding-right: 10px;
      }
      .cmn_btn {
        max-width: 220px;
      }
    }
    &__money--txt .title {
      font-size: 20px;
      letter-spacing: 1px;
      margin-right: -15px;
    }
  }
}
